import React from 'react';

function Care() {
    return (
        <div className='Care'>
            <h1 className='Care-h1'>Уход за изделиями</h1>
            <div className='Care-Info'>
                <h2 className='Care-Info-h2'>Повседневный уход</h2>
                <p className='Care-Info-p'>Поскольку многие из наших изделий изготовлены из необработанных металлов, со временем они имеют тенденцию тускнеть. Влажность и частое обращение играют ключевую роль в ускорении процесса, а также в поддержании индивидуального Рн-баланса кожи, поэтому правильный уход имеет ключевое значение. При этом, благодаря необработанной отделке большинства наших изделий, повторная полировка и уход за ними могут быть полностью выполнены заказчиком, что делает наши изделия инвестициями на всю жизнь.</p>
                <p className='Care-Info-p'>В идеале изделия из латуни и стерлингового серебра следует хранить в прохладном, темном месте, в герметичном пластиковом пакете, чтобы защитить их от влажности и солнечного света, которые ускоряют процесс потускнения.</p>
                <p className='Care-Info-p'>Если вы предпочитаете, чтобы ваши изделия выглядели незапятнанными, пожалуйста, чистите их после каждого использования (следуя приведенным ниже инструкциям), так как чем дольше вы ждете появления потускнения, тем сложнее их чистить. По крайней мере, старайтесь протирать их чистой тканью, чтобы удалить натуральные масла после каждого использования.</p>
            </div>
            <div className='Care-Info'>
                <h2 className='Care-Info-h2'>Предложения по полировке</h2>
                <p className='Care-Info-p'>Наш предпочтительный метод полировки - использование полировальных салфеток Sunshine. В них встроен полировальный состав, и они отлично справляются как с латунью, так и с серебром. Если вы предпочитаете жидкую полироль, мы рекомендуем Hagerty 100 All Metal Polish для латуни и Hagerty Silversmith's polish для чистого серебра.</p>
                <p className='Care-Info-p'>Мы также рекомендуем перед полировкой изделия салфетками Sunshine использовать мягкое мыло (Dove или Dr. Bronner's) просто для удаления остатков масел с поверхности, а затем высушить изделие чистым полотенцем или даже папиросной бумагой. Это подготовит изделие к более легкой полировке.</p>
                <p className='Care-Info-p'>Если деталь полая (напр. Двухцветные серьги Hepworth или шпильки Hepworth), пожалуйста, следите за тем, чтобы не погружать изделие в воду во время предварительной чистки. Существует вероятность того, что вода может попасть внутрь. Вместо этого используйте влажную тряпку с мылом и водой, а затем полировальные салфетки.</p>
            </div>
            <div className='Care-Info'>
                <h2 className='Care-Info-h2'>Уход за покрытыми металлом деталями</h2>
                <p className='Care-Info-p'>Если изделие покрыто золотом или родием, пожалуйста, не полируйте! Это приведет только к удалению покрытия. Кроме того, позаботьтесь о том, чтобы снять изделие перед купанием, принятием душа или плаванием, так как хлор агрессивно воздействует на хрупкое покрытие. Пожалуйста, также избегайте контакта с косметикой, лосьонами и духами, чтобы сохранить послевкусие.</p>
            </div>
        </div>
    );
}

export default Care;
