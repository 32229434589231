import React from "react";

const NewProducts = () =>{

    return(
        <div className="NewProducts">
            <p className="p-light">Покупайте наши</p>
            <p className="p-title-book">Новые Модели</p>
            
        </div>
    )
}

export default NewProducts;